/**  */

// eslint-disable-next-line
import React, { useState } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import Fade from '@material-ui/core/Fade';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Vars
import { cssNoPadding, themeVars } from '../../config/styles';

// Breakpoints
import { bp } from '../../config/breakpoints';

// Components
import Section from '../Common/Section/Section';
import SectionTitle from '../Common/Section/SectionTitle';
import LazyImage from '../Common/LazyImage';

const ContentTabbedImages = ({ title, items }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [images, setActiveImage] = useState(items[0].image.sizes.cm_image_tabs);
  const [isTransitioning, setIsTransitioning] = useState(true);

  function handleImage(index) {
    setActiveTab(index);

    // Set the transition state
    setIsTransitioning(false);

    // Wait for the transition to fade out before changing the image and fading in
    setTimeout(() => {
      setActiveImage(items[index].image.sizes.cm_image_tabs);
    }, 500);

    // Add a delay for transition fade
    setTimeout(() => {
      setIsTransitioning(true);
    }, 550);
  }

  const cssFadeWrapper = css`
    @media (min-width: ${bp.mn_xs}) {
      min-height: 500px;
      height: 500px;

      img {
        min-height: 500px;
        height: 500px;
      }
    }

    @media (min-width: ${bp.mn_lg}) {
      min-height: 800px;
      height: 800px;

      img {
        min-height: 800px;
        height: 800px;
      }
    }

    @media (min-width: ${bp.mn_xl}) {
      min-height: 948px;
      height: 948px;

      img {
        min-height: 948px;
        height: 948px;
      }
    }
  `;

  const cssImageWrapper = css`
    position: relative;
    line-height: 0;

    @media (min-width: ${bp.mn_xs}) {
      min-height: 0;
    }

    @media (min-width: ${bp.mn_lg}) {
      min-height: 948px;
    }

    img {
      margin: 0;
      padding: 0;
      min-width: 100%;
      object-fit: cover;
      object-position: center center;

      @media (max-width: ${bp.mx_lg}) {
        margin-bottom: 50px;
      }
    }
  `;

  const cssNav = css`
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 20px;

      button {
        cursor: pointer;

        @media (min-width: ${bp.mn_xs}) {
          text-align: center;
        }

        @media (min-width: ${bp.mn_lg}) {
          text-align: left;
        }
      }
    }
  `;

  const cssButtonActive = css`
    background-color: transparent;
    border: none;
    color: ${themeVars.root.primary};
    height: 70px;
    min-height: 7rem;
    position: relative;
    text-align: left;
    transition: 250ms ease-in-out all;
    width: 100%;

    &:before {
      content: '';
      border-color: transparent;
      border-style: solid;
      border-width: 35px 65px 35px 0;
      height: 0;
      left: -65px;
      position: absolute;
      top: 0;
      width: 0;
      transition: 250ms ease-in-out all;

      @media (min-width: ${bp.mn_xs}) {
        opacity: 0;
      }

      @media (min-width: ${bp.mn_md}) {
        opacity: 1;
      }
    }

    &.active {
      background-color: #fff;
      color: ${themeVars.root.secondary};

      &:before {
        border-color: transparent #fff transparent transparent;
        transition: 250ms ease-in-out all;
      }
    }
  `;

  const cssTabbedContent = css`
    margin-top: 15rem;

    @media (max-width: ${bp.mn_lg}) {
      margin-top: 0;
    }

    @media (max-width: ${bp.mx_lg}) {
      padding: 10rem 0;
    }
  `;

  return (
    <Section paddingBottom={0} paddingTop={0}>
      <Container css={cssNoPadding} fluid>
        <Row nogutter>
          <Col xl={6} lg={6} md={12} xs={12}>
            <Fade css={cssFadeWrapper} in={isTransitioning}>
              <div css={cssImageWrapper}>
                <LazyImage src={images} alt="" />
              </div>
            </Fade>
          </Col>

          <Col xl={6} lg={6} md={12} xs={12} css={cssTabbedContent}>
            <Container>
              <Row calign="center">
                <Col md={10} lg={8} push={{ md: 1, lg: 2 }}>
                  {title && <SectionTitle>Services Involved Within Project</SectionTitle>}
                  <nav>
                    <ul css={cssNav}>
                      {items.map((item, index) => (
                        <li key={shortid.generate()}>
                          <button
                            className={activeTab === index ? 'active' : 'not-active'}
                            css={activeTab === index ? cssButtonActive : cssButtonActive}
                            onClick={() => handleImage(index)}
                            type="button"
                          >
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

ContentTabbedImages.defaultProps = {
  title: null,
};

ContentTabbedImages.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default ContentTabbedImages;
