/**  */

// eslint-disable-next-line
import React from 'react';
import { ChevronRightOutlined, ChevronLeftOutlined } from '@material-ui/icons';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import Slider from 'react-slick';

// Assets
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { bp } from '../../config/breakpoints';

import Section from '../Common/Section/Section';

const cssArrow = css`
  font-size: 64px !important;
  color: #ddd;
  display: block;
  transition: 250ms ease-in-out all;

  &:hover {
    color: #fff;
  }
`;

const cssArrowLeft = css`
  position: absolute;
  left: 0;
  top: calc(50% - 32px);
  cursor: pointer;
  z-index: 10;
`;

const cssArrowRight = css`
  position: absolute;
  right: 0;
  top: calc(50% - 32px);
  cursor: pointer;
  z-index: 10;
`;

const cssWrapper = css`
  width: 100%;
  height: 100%;
  line-height: 0;
`;

const cssSlide = css`
  figure {
    margin: 0;
    width: 100%;
    height: auto;
    min-height: 1080px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;

    @media (min-width: ${bp.mn_xs}) {
      max-height: 500px;
      min-height: 500px;
    }
  }
`;

const ContentImageSlider = ({ images }) => {
  function CustomLeftArrow({ onClick }) {
    return (
      <div css={cssArrowLeft}>
        <ChevronLeftOutlined css={cssArrow} onClick={onClick} />
      </div>
    );
  }

  function CustomRightArrow({ onClick }) {
    return (
      <div css={cssArrowRight}>
        <ChevronRightOutlined css={cssArrow} onClick={onClick} />
      </div>
    );
  }

  const settings = {
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: false,
    draggable: true,
    infinite: true,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  };

  return (
    <Section paddingBottom={0} paddingTop={0}>
      <Slider {...settings} css={cssWrapper}>
        {images.map((slide) => {
          return (
            <div css={cssSlide} key={shortid.generate()}>
              <figure
                style={{
                  backgroundImage: `url(${slide.image.sizes.cm_image_slider})`,
                }}
              />
            </div>
          );
        })}
      </Slider>
    </Section>
  );
};

ContentImageSlider.defaultProps = {
  onClick: () => null,
};

ContentImageSlider.propTypes = {
  onClick: PropTypes.func,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ContentImageSlider;
