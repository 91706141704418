import React from 'react';
import { Container, Row } from 'react-grid-system';
import PropTypes from 'prop-types';

const ContentTeamList = ({ children }) => {
  return (
    <Container>
      <Row justify="center" align="center">
        {children}
      </Row>
    </Container>
  );
};

ContentTeamList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentTeamList;
