import React, { useLayoutEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { isEmpty } from 'lodash';

import { getPosts } from '../../../utils/posts';

// Vars
import { cssExtraMargin } from '../../../config/styles';

// Components
import ContentRecentProjectsItem from './ContentRecentProjectsItem';
import ContentRecentProjectsList from './ContentRecentProjectsList';
import Section from '../../Common/Section/Section';
import SectionSubtitle from '../../Common/Section/SectionSubtitle';
import SectionTitle from '../../Common/Section/SectionTitle';
import ContentRecentProjectsSkeleton from './ContentRecentProjectsSkeleton';
import ErrorComponent from '../../ErrorComponent';

const ContentRecentProjects = ({ title, subtitle, projects }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const [isError, setIsError] = useState(false);

  // Create an array of project IDs to filter all projects down by
  const projectIds = projects.map((project) => project.ID);

  useLayoutEffect(() => {
    getPosts('project')
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setIsloading(false);
        } else {
          throw new Error(500);
        }
      })
      .catch(() => {
        setIsError(true);
        setIsloading(false);
      });
  }, []);

  if (isLoading) {
    return <ContentRecentProjectsSkeleton />;
  }

  if (isError) {
    return <ErrorComponent />;
  }

  // Filter all the projects to the selected IDs from the content module
  const projectItems = data.filter((project) => projectIds.includes(project.id));

  return (
    <Section paddingBottom={0} paddingTop={title ? 10 : 0 || (subtitle ? 10 : 0)}>
      {subtitle && <SectionSubtitle centered>{subtitle}</SectionSubtitle>}
      {title && (
        <SectionTitle centered marginBottom={7.5}>
          {title}
        </SectionTitle>
      )}

      <Container fluid css={cssExtraMargin}>
        <ContentRecentProjectsList>
          {!isEmpty(data) &&
            projectItems.map((project, index) => {
              if (index <= 2) {
                return (
                  <ContentRecentProjectsItem
                    categories={project.acf.project_categories}
                    image={project.acf.featured_image}
                    key={shortid.generate()}
                    location={project.acf.location}
                    slug={project.slug}
                    title={project.title.rendered}
                    totalItems={projectItems.length}
                  />
                );
              }

              return null;
            })}
        </ContentRecentProjectsList>
      </Container>
    </Section>
  );
};

ContentRecentProjects.defaultProps = {
  subtitle: null,
  title: null,
};

ContentRecentProjects.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ContentRecentProjects;
