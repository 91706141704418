import axios from 'axios';

// Config
import { siteConfig } from '../config/site';

export function getDraftPosts(restBase, postId, revisionId) {
  return axios.get(
    `${siteConfig.cmsApiBaseUrl}/${restBase}/${postId}/${
      revisionId ? `revisions/${revisionId && `${revisionId}`}` : '?status=publish'
    }`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
      },
    }
  );
}

export function getPosts(restBase) {
  return axios.get(`${siteConfig.cmsApiBaseUrl}/${restBase}`);
}

export function getPage(restBase, pageId) {
  return axios.get(`${siteConfig.cmsApiBaseUrl}/${restBase}/${pageId}`);
}

export function getTaxonomy(restBase, catId) {
  return axios.get(`${siteConfig.cmsApiBaseUrl}/${restBase}/${catId}`);
}

export function getArchive(catRestBase, catId, restBase) {
  return Promise.all([
    axios.get(`${siteConfig.cmsApiBaseUrl}/${catRestBase}/${catId}`),
    axios.get(`${siteConfig.cmsApiBaseUrl}/${restBase}/?${catRestBase}=${catId}&_embed=1`),
  ]);
}

export function getProjectsPage(restBase, pageId) {
  return Promise.all([
    axios.get(`${siteConfig.cmsApiBaseUrl}/${restBase}/${pageId}`),
    axios.get(`${siteConfig.cmsApiBaseUrl}/project?_embed=1`),
  ]);
}

export function getNewsPage(restBase, pageId) {
  return Promise.all([
    axios.get(`${siteConfig.cmsApiBaseUrl}/${restBase}/${pageId}`),
    axios.get(`${siteConfig.cmsApiBaseUrl}/posts?_embed=1`),
  ]);
}

export default null;
