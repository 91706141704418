/**  */

// eslint-disable-next-line
import React from 'react';
import { Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { rgba } from 'polished';
import PropTypes from 'prop-types';

// Breakpoints
import { bp } from '../../../config/breakpoints';

// Vars
import { themeVars } from '../../../config/styles';
import LazyImage from '../../Common/LazyImage';

const ContentTeamItem = ({ email, position, linkedinProfileUrl, name, image }) => {
  const cssWrapper = css`
    margin-bottom: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${bp.mn_xs}) {
      &:nth-last-of-type(-n + 1) {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${bp.mn_md}) {
      &:nth-last-of-type(-n + 2) {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${bp.mn_xl}) {
      &:nth-last-of-type(-n + 4) {
        margin-bottom: 0;
      }
    }
  `;

  const cssName = css`
    font-size: 2.4rem;
    color: ${themeVars.root.primary};
    font-weight: 600;
    margin: 3rem 0 0.75rem;
  `;

  const cssPosition = css`
    color: ${themeVars.root.primary};
    font-size: 1.5rem;
  `;

  const cssLinksList = css`
    list-style: none;
    margin: 0;
    padding: 0;
  `;

  const cssLinks = css`
    color: ${rgba(themeVars.root.primary, 0.6)};
    margin: 0;
  `;

  return (
    <Col xl={3} md={6} sm={12} xs={12} css={cssWrapper}>
      <LazyImage src={image.sizes.cm_team} alt="" />

      <p css={cssName}>{name}</p>
      <p css={cssPosition}>{position}</p>

      <ul css={cssLinksList}>
        {email && (
          <li>
            <a css={cssLinks} href={`mailto:${email}`}>
              {email}
            </a>
          </li>
        )}
        {linkedinProfileUrl && (
          <li>
            <a css={cssLinks} href={linkedinProfileUrl} rel="noopener noreferrer" target="_blank">
              Linkedin
            </a>
          </li>
        )}
      </ul>
    </Col>
  );
};

ContentTeamItem.defaultProps = {
  email: null,
  linkedinProfileUrl: PropTypes.string,
};

ContentTeamItem.propTypes = {
  email: PropTypes.string,
  linkedinProfileUrl: PropTypes.string,
  position: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      cm_team: PropTypes.string,
    }),
  }).isRequired,
};

export default ContentTeamItem;
