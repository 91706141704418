/**  */

// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import { Button } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import PropTypes from 'prop-types';

// MUI Overrides
const styles = {
  root: {
    borderRadius: 0,
    fontSize: 16,
    fontWeight: 600,

    letterSpacing: '1.25px',
    textTransform: 'uppercase',
    width: '100%',
    height: '100%',
    color: '#77A8B1',
  },
};

const MUIButtonDownload = (props) => {
  const { classes } = props;

  const cssIcon = css`
    margin-left: 15px;
  `;

  return (
    <Button
      className={classes.root}
      color="primary"
      size="large"
      variant="contained"
      type="button"
      {...props}
    >
      {props.children}
      <SaveAltIcon css={cssIcon} />
    </Button>
  );
};

MUIButtonDownload.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(MUIButtonDownload);
