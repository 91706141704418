/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { Link } from 'gatsby';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PropTypes from 'prop-types';

// Components
import MUIButtonText from '../Common/Button/MUIButtonText';
import Section from '../Common/Section/Section';
import SectionTitle from '../Common/Section/SectionTitle';

const ContentCta = ({ title, btnLabel, btnPage }) => {
  const cssBtnWrapper = css`
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  return (
    <Section>
      <Container>
        <Row>
          <Col>
            {title && (
              <SectionTitle centered marginBottom={5.5}>
                {title}
              </SectionTitle>
            )}

            <Link css={cssBtnWrapper} to={`/${btnPage.post_name}/`}>
              <MUIButtonText color="primary">
                {btnLabel}
                <ArrowRightAltIcon color="secondary" />
              </MUIButtonText>
            </Link>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

ContentCta.propTypes = {
  /** The title of the call to action */
  title: PropTypes.string.isRequired,
  /** The label text for the button */
  btnLabel: PropTypes.string.isRequired,
  /** The relative page slug for the button */
  btnPage: PropTypes.shape({
    post_name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ContentCta;
