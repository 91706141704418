import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Components
import Section from '../../Common/Section/Section';
import ContentTeamItem from './ContentTeamItem';
import ContentTeamList from './ContentTeamList';
import SectionSubtitle from '../../Common/Section/SectionSubtitle';
import SectionTitle from '../../Common/Section/SectionTitle';

const ContentTeam = ({ title, subtitle, members }) => {
  return (
    <Section>
      <Container>
        <Row>
          <Col>
            {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
            {title && <SectionTitle>{title}</SectionTitle>}
          </Col>
        </Row>
      </Container>

      <ContentTeamList>
        {members.map((member) => (
          <ContentTeamItem
            email={member.email}
            image={member.image}
            key={shortid.generate()}
            linkedinProfileUrl={member.linkedin_profile_url}
            name={member.name}
            position={member.position}
          />
        ))}
      </ContentTeamList>
    </Section>
  );
};

ContentTeam.defaultProps = {
  title: null,
  subtitle: null,
};

ContentTeam.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ContentTeam;
