/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { rgba } from 'polished';
import Hexagon from 'react-hexagon';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Breakpoints
import { bp } from '../../config/breakpoints';

// Components
import { themeVars } from '../../config/styles';
import Section from '../Common/Section/Section';
import SectionSubtitle from '../Common/Section/SectionSubtitle';
import SectionTitle from '../Common/Section/SectionTitle';

const ContentServicesGrid = ({ title, subtitle, services }) => {
  const cssHex = css`
    position: relative;
    max-width: 354px;
    clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 70%, 100% 100%, 13% 100%, 0 83%, 0 0);
    margin: 10px;
  `;

  const cssHexWrapper = css`
    .hex-item {
      margin-bottom: 25px;

      &:nth-last-of-type(-n + 1) {
        margin-bottom: 0;
      }

      @media (min-width: ${bp.mn_md}) {
        &:nth-last-of-type(-n + 2) {
          margin-bottom: 0;
        }
      }

      @media (min-width: ${bp.mn_lg}) {
        &:nth-last-of-type(-n + 3) {
          margin-bottom: 0;
        }
      }

      @media (min-width: ${bp.mn_xl}) {
        &:nth-last-of-type(-n + 4) {
          margin-bottom: 0;
        }
      }
    }
  `;

  const cssHexContent = css`
    position: absolute;
    bottom: 0px;
    background-color: ${rgba(themeVars.root.primary, 0.6)};
    height: 25%;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 2.3rem;
    color: #fff;
    padding: 25px 0 0;
    text-align: center;
    clip-path: polygon(30% 0%, 70% 0%, 100% 0, 100% 70%, 100% 100%, 13% 100%, 0 83%, 0 0);

    @media (max-width: ${bp.mx_sm}) {
      font-size: 1.6rem;
      padding: 5px 10px;
    }

    @media (max-width: ${bp.mx_lg}) {
      font-size: 2.0rem;
    }

    @media (min-width: ${bp.mn_lg}) {
      height: 25%;
      font-size: 2.2rem;
    }

    svg {
      border: none;
    }
  `;

  return (
    <Section backgroundColor="##E5ECEF">
      <Container>
        {subtitle && <SectionSubtitle centered>{subtitle}</SectionSubtitle>}
        {title && (
          <SectionTitle centered marginBottom={7}>
            {title}
          </SectionTitle>
        )}

        <Row css={cssHexWrapper}>
          {services.map((service) => (
            <Col xl={3} lg={4} md={6} sm={12} xs={12} key={shortid.generate()}>
              <figure css={cssHex}>
                <img src={service.image.sizes.cm_services_grid}/>
                <figcaption css={cssHexContent}>
                  <p>{service.name}</p>
                </figcaption>
              </figure>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

ContentServicesGrid.defaultProps = {
  subtitle: null,
  title: null,
};

ContentServicesGrid.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default ContentServicesGrid;
