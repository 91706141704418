/* eslint-disable react/prop-types */
import React from 'react';
import shortid from 'shortid';
import { isIE } from 'react-device-detect';

// Components
import ContentCta from './ContentCta';
import ContentFourCol from './ContentFourCol';
import ContentImageSlider from './ContentImageSlider';
import ContentImageText from './ContentImageText';
import ContentJobs from './ContentJobs';
import ContentLatestNews from './ContentLatestNews';
import ContentRecentProjects from './ContentRecentProjects/ContentRecentProjects';
import ContentTabbedImages from './ContentTabbedImages';
import ContentTeam from './ContentTeam/ContentTeam';
import ContentText from './ContentText';
import ContentTextLeft from './ContentTextLeft';
import ContentServicesGrid from './ContentServicesGrid';
import ContentServicesGridIe from './ContentServicesGridIE';

const ContentModules = ({ module }) => {
  switch (module.acf_fc_layout) {
    case 'image_tabs':
      return (
        <ContentTabbedImages title={module.title} items={module.items} key={shortid.generate()} />
      );

    case 'image_text':
      return (
        <ContentImageText
          button={module.button}
          buttonLabel={module.button_label}
          buttonUrl={module.button_url}
          content={module.content}
          image={module.image}
          key={shortid.generate()}
          layout={module.layout}
          subtitle={module.subtitle}
          title={module.title}
        />
      );

    case 'text':
      return (
        <ContentText
          content={module.content}
          key={shortid.generate()}
          subtitle={module.subtitle}
          title={module.title}
        />
      );

    case 'four_col':
      return (
        <ContentFourCol
          columns={module.columns}
          key={shortid.generate()}
          subtitle={module.subtitle}
          title={module.title}
        />
      );

    case 'cta':
      return (
        <ContentCta
          btnLabel={module.btn_label}
          btnPage={module.btn_page}
          key={shortid.generate()}
          title={module.title}
        />
      );

    case 'jobs':
      return <ContentJobs title={module.title} jobs={module.jobs} key={shortid.generate()} />;

    case 'team':
      return (
        <ContentTeam
          key={shortid.generate()}
          members={module.members}
          subtitle={module.subtitle}
          title={module.title}
        />
      );

    case 'image_slider':
      return <ContentImageSlider images={module.images} key={shortid.generate()} />;

    case 'text_left':
      return (
        <ContentTextLeft title={module.title} content={module.content} key={shortid.generate()} />
      );

    case 'latest_news':
      return (
        <ContentLatestNews
          key={shortid.generate()}
          subtitle={module.subtitle}
          title={module.title}
        />
      );

    case 'services':
      if (isIE) {
        return (
          <ContentServicesGridIe
            key={shortid.generate()}
            services={module.services}
            subtitle={module.subtitle}
            title={module.title}
          />
        );
      }
      return (
        <ContentServicesGrid
          key={shortid.generate()}
          services={module.services}
          subtitle={module.subtitle}
          title={module.title}
        />
      );

    case 'recent_projects':
      return (
        <ContentRecentProjects
          key={shortid.generate()}
          projects={module.projects}
          subtitle={module.subtitle}
          title={module.title}
        />
      );

    default:
      return null;
  }
};

export default ContentModules;
