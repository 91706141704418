import React from 'react';
import { Row } from 'react-grid-system';
import PropTypes from 'prop-types';

const ContentRecentProjectsList = ({ children }) => {
  return <Row nogutter>{children}</Row>;
};

ContentRecentProjectsList.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentRecentProjectsList;
