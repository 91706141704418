/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Col } from 'react-grid-system';
import shortid from 'shortid';
import { css, jsx } from '@emotion/core';

// Vars
import { cssNoPadding } from '../../../config/styles';

// Components
import ContentRecentProjectsList from './ContentRecentProjectsList';
import Section from '../../Common/Section/Section';
import SectionTitle from '../../Common/Section/SectionTitle';
import Skeleton from '../../Common/Skeleton/Skeleton';

const ContentRecentProjectsSkeleton = () => {
  const cssWrapper = css`
    height: 80rem;
    min-height: 80rem;
    width: 100%;
  `;

  return (
    <Section paddingBottom={0}>
      <SectionTitle centered marginBottom={7.5}>
        <Skeleton width={200} />
      </SectionTitle>
      <Container fluid css={cssNoPadding}>
        <ContentRecentProjectsList>
          {Array(...new Array(3)).map(() => (
            <Col xl={4} lg={6} md={6} sm={12} xs={12} css={cssWrapper} key={shortid.generate()}>
              <Skeleton height={800} />
            </Col>
          ))}
        </ContentRecentProjectsList>
      </Container>
    </Section>
  );
};

export default ContentRecentProjectsSkeleton;
