/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

// Components
import Section from '../Common/Section/Section';
import SectionTitle from '../Common/Section/SectionTitle';
import SectionSubtitle from '../Common/Section/SectionSubtitle';

// Breakpoints
import { bp } from '../../config/breakpoints';

// Vars
import { themeVars } from '../../config/styles';

const cssContent = css`
  color: #000;

  ul {
    margin-bottom: 30px;
    padding-left: 20px;
  }

  ol {
    margin-bottom: 30px;
  }

  a {
    color: ${themeVars.root.primary};
    text-decoration: underline;
  }

  blockquote {
    font-size: 2rem;
    font-style: italic;
    margin-bottom: 3.5rem;
    margin-bottom: 3.5rem;
    margin-left: 50px;
    position: relative;
    padding: 0 35px;

    &:before {
      content: '"';
      font-size: 13rem;
      left: -80px;
      position: absolute;
      top: -15px;
      padding: 0;
      line-height: 1;
      color: ${themeVars.root.primary};
    }
  }

  @media (max-width: ${bp.mx_md}) {
    text-align: center;
  }

  p {
    margin-bottom: 3.5rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const ContentText = ({ title, subtitle, content }) => {
  return (
    <Section>
      <div>
        <Container>
          <Row>
            <Col>
              {subtitle && <SectionSubtitle centered>{subtitle}</SectionSubtitle>}
              {title && (
                <SectionTitle centered fontSize={4.5} marginBottom={4.5}>
                  {title}
                </SectionTitle>
              )}

              <div
                css={cssContent}
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Section>
  );
};

ContentText.defaultProps = {
  subtitle: null,
  title: null,
};

ContentText.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string.isRequired,
};

export default ContentText;
