/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Vars
import { themeVars } from '../../config/styles';

// Components
import Section from '../Common/Section/Section';
import SectionSubtitle from '../Common/Section/SectionSubtitle';
import SectionTitle from '../Common/Section/SectionTitle';

const ContentServicesGridIe = ({ title, subtitle, services }) => {
  const cssHexIe = css`
    margin: 0 auto;
    max-width: 354px;
    position: relative;
  `;

  const cssImgIe = css`
    margin-bottom: 25px;
  `;

  const cssTitleIe = css`
    color: ${themeVars.root.primary};
    font-size: 2.8rem;
    font-weight: 600;
    text-align: center;
  `;

  return (
    <Section backgroundColor="#FFF">
      <Container>
        {subtitle && <SectionSubtitle centered>{subtitle}</SectionSubtitle>}
        {title && (
          <SectionTitle centered marginBottom={7}>
            {title}
          </SectionTitle>
        )}

        <Row>
          {services.map((service) => (
            <Col
              className="hex-item-ie"
              xl={3}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              key={shortid.generate()}
            >
              <figure css={cssHexIe}>
                <img css={cssImgIe} src={service.image.sizes.cm_services_grid} alt="" />

                <figcaption>
                  <p css={cssTitleIe}>{service.name}</p>
                </figcaption>
              </figure>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

ContentServicesGridIe.defaultProps = {
  subtitle: null,
  title: null,
};

ContentServicesGridIe.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default ContentServicesGridIe;
