/**  */

// eslint-disable-next-line
import React from 'react';
import { Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Vars
import { themeVars } from '../../../config/styles';
import { bp } from '../../../config/breakpoints';

// Components
import MUIButtonTag from '../../Common/Button/MUIButtonTag';
import TagsList from '../../Common/Tags/TagsList';
import TagItem from '../../Common/Tags/TagItem';

const ContentRecentProjectItem = ({ title, location, categories, image, slug, totalItems }) => {
  const cssWrapper = css`
    width: 100%;
    position: relative;
    background: url(${image.sizes.cm_recent_projects_small}) center center no-repeat;
    background-size: cover;
    z-index: 1;

    @media (min-width: ${bp.mn_xs}) {
      height: 70vh;
    }

    @media (min-width: ${bp.mn_xl}) {
      height: 80vh;
    }

    @media (min-width: ${bp.mn_lg}) {
      padding-left: 5vw;
      padding-right: 5vw;
    }

    &:before {
      position: absolute;
      pointer-events: none;
      bottom: 0;
      background: linear-gradient(180deg, rgba(20,37,94,0) 65%, #14255E 100%);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      pointer-events: none;
      transition: 250ms ease-in-out all;
    }

    &:hover {
      &:before {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  `;

  const cssFooter = css`
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0px 5rem 10rem;
    @media (max-width: ${bp.mn_md}) {
      padding: 0px 2rem 12rem;
    }
  `;

  const cssLocation = css`
    text-transform: uppercase;
    color: ${themeVars.root.secondary};
    font-weight: 600;
    margin-bottom: 0.5rem;
  `;

  const cssName = css`
    font-size: 2.4rem;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
  `;

  const cssLink = css`
    display: block;
    width: 100%;
    height: 100%;
  `;

  return (
    <Col
      xl={totalItems === 2 ? 6 : 4}
      lg={totalItems === 2 ? 6 : 4}
      md={12}
      sm={12}
      xs={12}
      css={cssWrapper}
    >
      <Link css={cssLink} to={`/projects/${slug}/`} aria-label={title}>
        <figure>
          <footer css={cssFooter}>
            <p css={cssLocation}>{location}</p>
            <p css={cssName}>{title}</p>
          </footer>
        </figure>
      </Link>

      <TagsList>
        {categories.map((category) => {
          return (
            <TagItem
              baseSlug="projects"
              key={shortid.generate()}
              name={category.name}
              slug={category.slug}
              taxSlug="category"
            >
              <Link to={`/projects/category/${category.slug}/`}>
                <MUIButtonTag>{category.name}</MUIButtonTag>
              </Link>
            </TagItem>
          );
        })}
      </TagsList>
    </Col>
  );
};

ContentRecentProjectItem.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      cm_recent_projects_small: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
};

export default ContentRecentProjectItem;
