import React from 'react';
import Img from 'react-image';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';
import Skeleton from './Skeleton/Skeleton';

const LazyImage = (props) => {
  const { src, alt, threshold } = props;

  const [ref, inView] = useInView({
    threshold: threshold || 0,
    triggerOnce: true,
  });

  return (
    <div ref={ref}>{inView && <Img src={src} alt={alt} loader={<Skeleton height="100%" />} />}</div>
  );
};

LazyImage.defaultProps = {
  alt: null,
  threshold: 0,
};

LazyImage.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]).isRequired,
  alt: PropTypes.string,
  threshold: PropTypes.number,
};

export default LazyImage;
