/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { Link } from 'gatsby';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PropTypes from 'prop-types';

import { bp } from '../../config/breakpoints';
import { themeVars } from '../../config/styles';

// Components
import MUIButtonText from '../Common/Button/MUIButtonText';
import Section from '../Common/Section/Section';
import SectionSubtitle from '../Common/Section/SectionSubtitle';
import SectionTitle from '../Common/Section/SectionTitle';
import LazyImage from '../Common/LazyImage';

const ContentImageText = ({
  button,
  buttonLabel,
  buttonUrl,
  content,
  image,
  layout,
  subtitle,
  title,
}) => {
  const cssItem = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  `;

  const cssContent = css`
    @media (max-width: ${bp.mx_md}) {
      p:last-of-type {
        margin-bottom: ${button ? 'margin-bottom: 50px' : 0};
      }
    }
  `;

  const cssButton = css`
    @media (max-width: ${bp.mx_md}) {
      margin-bottom: 50px;
    }
  `;

  const cssImage = css`
    @media (max-width: ${bp.mx_lg}) {
      margin-bottom: 50px;
      display: block;
    }
  `;

  const cssRow = css`
    @media (max-width: ${bp.mx_lg}) {
      ${layout === 'image_left' && 'flex-direction: column-reverse;'}
    }
  `;

  const cssIcon = css`
    margin-left: 15px;
    color: ${themeVars.root.secondary};
  `;

  return (
    <Section>
      <Container>
        <Row css={cssRow}>
          {layout === 'image_left' && (
            <>
              <Col lg={5} md={12} sm={12}>
                <div css={cssItem}>
                  {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
                  {title && <SectionTitle>{title}</SectionTitle>}

                  <div
                    css={cssContent}
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  />

                  {button && (
                    <Link to={`/${buttonUrl}/`} css={cssButton}>
                      <MUIButtonText>
                        {buttonLabel} <ArrowRightAltIcon css={cssIcon} />
                      </MUIButtonText>
                    </Link>
                  )}
                </div>
              </Col>
              <Col lg={6} md={12} sm={12} push={{ lg: 1 }}>
                <div css={cssImage}>
                  <LazyImage src={image.sizes.cm_image_text} alt="" />
                </div>
              </Col>
            </>
          )}

          {layout === 'image_right' && (
            <>
              <Col lg={6} md={12} sm={12}>
                <div css={cssImage}>
                  <LazyImage css={cssImage} src={image.sizes.cm_image_text} alt="" />
                </div>
              </Col>

              <Col lg={5} md={12} sm={12} push={{ lg: 1 }}>
                <div css={cssItem}>
                  {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
                  {title && <SectionTitle>{title}</SectionTitle>}

                  <div
                    css={cssContent}
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  />

                  {button && (
                    <Link to={buttonUrl}>
                      <MUIButtonText color={themeVars.root.secondary}>
                        {buttonLabel} <ArrowRightAltIcon css={cssIcon} />
                      </MUIButtonText>
                    </Link>
                  )}
                </div>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </Section>
  );
};

ContentImageText.defaultProps = {
  button: false,
  buttonLabel: null,
  buttonUrl: null,
  subtitle: null,
  title: null,
};

ContentImageText.propTypes = {
  button: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  content: PropTypes.string.isRequired,
  image: PropTypes.shape({
    sizes: PropTypes.shape({
      cm_image_text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  layout: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default ContentImageText;
