/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

// Components
import Section from '../Common/Section/Section';
import SectionTitle from '../Common/Section/SectionTitle';

const cssContent = css`
  color: #000;

  p {
    margin-bottom: 3.5rem;
  }
`;

const ContentTextLeft = ({ title, content }) => {
  return (
    <Section>
      <div>
        <Container>
          <Row>
            <Col lg={7} md={12} sm={12}>
              {title && (
                <SectionTitle fontSize={4} marginBottom={3}>
                  {title}
                </SectionTitle>
              )}

              <p
                css={cssContent}
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Section>
  );
};

ContentTextLeft.defaultProps = {
  title: null,
};

ContentTextLeft.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string.isRequired,
};

export default ContentTextLeft;
