/**  */

// eslint-disable-next-line
import React from 'react';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';

// Vars
import { themeVars } from '../../../config/styles';
import { bp } from '../../../config/breakpoints';

const SectionSubtitle = ({ children, centered }) => {
  const cssSubtitle = css`
    color: ${themeVars.root.secondary};
    margin: 0 0 1rem 0;
    font-size: 1.4rem;
    font-weight: 600;
    ${centered && 'text-align: center'};

    @media (max-width: ${bp.mx_lg}) {
      text-align: center;
    }
  `;

  return <p css={cssSubtitle}>{children}</p>;
};

SectionSubtitle.defaultProps = {
  centered: false,
};

SectionSubtitle.propTypes = {
  children: PropTypes.string.isRequired,
  /** Centre align the component */
  centered: PropTypes.bool,
};

export default SectionSubtitle;
