import React from 'react';
import { Container, Row, Col } from 'react-grid-system';

import Section from './Common/Section/Section';

const ErrorComponent = () => (
  <Section>
    <Container>
      <Row>
        <Col>
          <p>There was an error fetching data. Please try again later.</p>
        </Col>
      </Row>
    </Container>
  </Section>
);

export default ErrorComponent;
