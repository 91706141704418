/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { Paper } from '@material-ui/core';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { bp } from '../../config/breakpoints';

// Components
import Section from '../Common/Section/Section';
import SectionTitle from '../Common/Section/SectionTitle';
import MUIButtonDownload from '../Common/Button/MUIButtonDownload';

const ContentJobs = ({ title, jobs }) => {
  const cssItemWrapper = css`
    margin-bottom: 1.5rem;
    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: ${bp.mx_md}) {
      margin-bottom: 50px;
    }
  `;

  const cssLabel = css`
    font-size: 1.4rem;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 20px 0;

    @media (max-width: ${bp.mx_md}) {
      display: none;
    }
  `;

  const cssJobTitle = css`
    font-size: 2.2rem;
    font-weight: 600;
    margin: 0;
    height: 85px;
    display: flex;
    align-items: center;
    padding-left: 4rem;
  `;

  const cssJobPublished = css`
    color: ${rgba('#000', 0.6)};
    margin: 0;
    display: flex;
    align-items: center;
    height: 85px;
  `;

  const jobsTotal = `${jobs.length} ${title}`;

  return (
    <Section>
      <Container>
        <Row>
          <Col>
            <SectionTitle>{jobsTotal}</SectionTitle>

            <div>
              <Row nogutter>
                <Col xl={6} md={4} xs={8} offset={{ xl: 4 }}>
                  <p css={cssLabel}>Published</p>
                </Col>
                <Col xl={2} md={2} xs={4}>
                  <p css={cssLabel}>Job Details</p>
                </Col>
              </Row>
            </div>

            {jobs.map((job) => (
              <Paper css={cssItemWrapper} key={shortid.generate()}>
                <Row nogutter>
                  <Col xl={4} md={4} xs={8}>
                    <p css={cssJobTitle}>{job.title}</p>
                  </Col>

                  <Col xl={6} md={4} xs={4}>
                    <p css={cssJobPublished}>{job.date}</p>
                  </Col>

                  <Col xl={2} md={4} xs={12}>
                    <MUIButtonDownload href={job.resource.url}>Download</MUIButtonDownload>
                  </Col>
                </Row>
              </Paper>
            ))}
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

ContentJobs.propTypes = {
  /** The title for the section */
  title: PropTypes.string.isRequired,
  /** Array of job data */
  jobs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ContentJobs;
