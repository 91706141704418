/**  */

// eslint-disable-next-line
import React, { useLayoutEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import { ChevronRightOutlined, ChevronLeftOutlined, ArrowRightAlt } from '@material-ui/icons';
import { Container, Row, Col, Visible } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import { isEmpty } from 'lodash';
import { Link } from 'gatsby';
import { rgba } from 'polished';
import moment from 'moment';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import Slider from 'react-slick';
import TextTruncate from 'react-text-truncate';

import { bp } from '../../config/breakpoints';

// Assets
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Vars
import { themeVars } from '../../config/styles';

// Components
import Section from '../Common/Section/Section';
import SectionSubtitle from '../Common/Section/SectionSubtitle';
import SectionTitle from '../Common/Section/SectionTitle';
import MUIButtonText from '../Common/Button/MUIButtonText';
import { getPosts } from '../../utils/posts';
import ErrorComponent from '../ErrorComponent';

const cssWrapper = css`
  line-height: 0;

  /* the slides */
  .slick-slide {
    padding: 0 20px;
  }
  /* the parent */
  .slick-list {
    margin: 0 -20px;
  }

  .slick-dots {
    position: absolute;
    left: 90px;
    width: auto;
    bottom: -52px;

    & > li {
      &:hover {
        .hexagon {
          background-color: ${themeVars.root.primary};
        }

        .hexagon:before {
          border-bottom: 3.46px solid ${themeVars.root.primary};
        }

        .hexagon:after {
          border-top: 3.46px solid ${themeVars.root.primary};
        }
      }
    }
  }

  .hexagon {
    position: relative;
    width: 12px;
    height: 6.93px;
    background-color: #b1b9bf;
    margin: 3.46px 0;
  }

  .hexagon:before,
  .hexagon:after {
    content: '';
    position: absolute;
    width: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    left: 0; /* Fix for placement */
  }

  .hexagon:before {
    bottom: 100%;
    border-bottom: 3.46px solid #b1b9bf;
  }

  .hexagon:after {
    top: 100%;
    width: 0;
    border-top: 3.46px solid #b1b9bf;
  }

  .slick-active {
    .hexagon {
      background-color: ${themeVars.root.primary};
    }

    .hexagon:before {
      border-bottom: 3.46px solid ${themeVars.root.primary};
    }

    .hexagon:after {
      border-top: 3.46px solid ${themeVars.root.primary};
    }
  }
`;

const cssArrow = css`
  font-size: 48px !important;
  color: #000;
  display: block;
  transition: 250ms ease-in-out all;

  &:hover {
    color: ${themeVars.root.secondary};
  }
`;

const cssArrowLeft = css`
  position: absolute;
  left: -20px;
  bottom: -64px;
  cursor: pointer;
  z-index: 10;
`;

const cssArrowRight = css`
  position: absolute;
  left: 30px;
  bottom: -64px;
  cursor: pointer;
  z-index: 10;
`;

const cssSlideContent = css`
  padding: 3rem;
`;

const cssSlideWrapper = css`
  overflow: hidden;
`;

const cssDate = css`
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  color: ${themeVars.root.secondary};
  margin: 0 0 5px;
`;

const cssTitle = css`
  font-size: 1.8rem;
  font-weight: bolder;
  text-transform: uppercase;
  color: ${themeVars.root.primary};
`;

const cssNewsImage = css`
  margin: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  min-height: 255px;
  max-height: 255px;
  height: 255px;
`;

const cssContent = css`
  color: ${rgba('#000', 0.6)};
`;

const cssIntroContainer = css`
    max-height: 50px;
    min-height: 50px;
    width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const cssCard = css`
  @media (max-width: ${bp.mn_md}) {
    height: 515px;
    min-height: 515px;
    max-height: 515px;
  }
`;

const ContentLatestNews = ({ title, subtitle }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const [isError, setIsError] = useState(false);

  useLayoutEffect(() => {
    getPosts('posts')
      .then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setIsloading(false);
        } else {
          throw new Error(500);
        }
      })
      .catch(() => {
        setIsError(true);
        setIsloading(false);
      });
  }, []);

  function CustomLeftArrow({ onClick }) {
    return (
      <div css={cssArrowLeft}>
        <ChevronLeftOutlined css={cssArrow} onClick={onClick} />
      </div>
    );
  }

  function CustomRightArrow({ onClick }) {
    return (
      <div css={cssArrowRight}>
        <ChevronRightOutlined css={cssArrow} onClick={onClick} />
      </div>
    );
  }

  const settings = {
    dots: true,
    arrows: true,
    draggable: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <CustomRightArrow />,
    prevArrow: <CustomLeftArrow />,
    customPaging: () => <div className="hexagon" />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1259,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <ErrorComponent />;
  }

  return (
    <>
      {/* Only show the component if there are at least two posts */}
      {!isEmpty(data) && data.length >= 2 && (
        <Section paddingBottom={15} backgroundColor="#E5ECEF">
          <Container>
            <Row>
              <Col>
                {subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
                {title && <SectionTitle>{title}</SectionTitle>}
                <Slider {...settings} css={cssWrapper}>
                  {data.map((item) => {
                    return (
                      <div css={cssSlideWrapper} key={shortid.generate()}>
                        <Card>
                          <Row css={cssCard}>
                            <Col md={6}>
                              {/* TODO: */}
                              <img
                                css={cssNewsImage}
                                src={item.acf.image.sizes.cm_latest_news}
                                alt=""
                              />
                            </Col>
                            <Col md={6}>
                              <div css={cssSlideContent}>
                                <p css={cssDate}>{moment(item.date).format('LL')}</p>
                                <p css={cssTitle}>{item.title.rendered}</p>

                                {item.acf.intro && (
                                  <div css={cssIntroContainer}>
                                    {item.acf.intro}
                                  </div>
                                )}
                                <Link to={`/news/${item.slug}/`}>
                                  <MUIButtonText>
                                    Read more <ArrowRightAlt color="secondary" />
                                  </MUIButtonText>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    );
                  })}
                </Slider>
              </Col>
            </Row>
          </Container>
        </Section>
      )}
    </>
  );
};

ContentLatestNews.defaultProps = {
  onClick: () => null,
  title: null,
  subtitle: null,
};

ContentLatestNews.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ContentLatestNews;
