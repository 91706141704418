/**  */

// eslint-disable-next-line
import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Components
import Section from '../Common/Section/Section';
import SectionTitle from '../Common/Section/SectionTitle';
import SectionSubtitle from '../Common/Section/SectionSubtitle';

// Breakpoints
import { bp } from '../../config/breakpoints';

const ContentFourCol = ({ title, subtitle, columns }) => {
  const cssTitle = css`
    font-size: 2.4rem;
    font-weight: 600;
    color: #000;
    margin-bottom: 2.5rem;
  `;

  const cssContent = css`
    text-align: center;
  `;

  const cssItemWrapper = css`
    .item {
      margin-bottom: 3rem;

      @media (min-width: ${bp.mn_xs}) {
        &:nth-last-of-type(-n + 1) {
          margin-bottom: 0;
        }
      }

      @media (min-width: ${bp.mn_lg}) {
        &:nth-last-of-type(-n + 2) {
          margin-bottom: 0;
        }
      }

      @media (min-width: ${bp.mn_lg}) {
        &:nth-last-of-type(-n + 3) {
          margin-bottom: 0;
        }
      }

      @media (min-width: ${bp.mn_xl}) {
        &:nth-last-of-type(-n + 4) {
          margin-bottom: 0;
        }
      }

      p {
        margin: 0;
      }
    }
  `;

  return (
    <Section>
      {subtitle && <SectionSubtitle centered>{subtitle}</SectionSubtitle>}
      {title && (
        <SectionTitle centered marginBottom={8}>
          Our Values
        </SectionTitle>
      )}
      <Container>
        <Row css={cssItemWrapper}>
          {columns.map((column) => (
            <Col xl={3} lg={4} md={6} sm={12} xs={12} key={shortid.generate()} className="item">
              <div css={cssContent}>
                <h4 css={cssTitle}>{column.title}</h4>
                <p>{column.content}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

ContentFourCol.defaultProps = {
  subtitle: null,
  title: null,
};

ContentFourCol.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ContentFourCol;
