/**  */

// eslint-disable-next-line
import React, { PureComponent } from 'react';
import { css, jsx, keyframes } from '@emotion/core';
import PropTypes from 'prop-types';
import shortid from 'shortid';

const Skeleton = ({ count, duration, width, height, circle, wrapper }) => {
  const cssDefaultBaseColor = '#eee';

  const cssDefaultHighlightColor = '#f5f5f5';

  const cssAnimation = keyframes`
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
`;

  const skeletonClass = css`
    background-color: ${cssDefaultBaseColor};
    background-image: linear-gradient(
      90deg,
      ${cssDefaultBaseColor},
      ${cssDefaultHighlightColor},
      ${cssDefaultBaseColor}
    );
    background-size: 200px 100%;
    background-repeat: no-repeat;
    border-radius: 0;
    display: inline-block;
    line-height: 1;
    width: 100%;
    height: 100%;
  `;
  // The array of items
  const elements = [];

  // Create the skeleton elements based on the count
  for (let i = 0; i < count; i += 1) {
    const style = {
      animation: `${cssAnimation} ${String(duration)}s ease-in-out infinite`,
    };
    if (width != null) {
      style.width = width;
    }
    if (height != null) {
      style.height = height;
    }
    if (height === '100vh') {
      style.height = height;
    }
    if (width !== null && height !== null && circle) {
      style.borderRadius = '50%';
    }

    // Add each element to the elements array based on the count
    elements.push(
      <span key={shortid.generate()} css={[skeletonClass, style]}>
        &zwnj;
      </span>
    );
  }

  const Wrapper = wrapper;

  return (
    <span>
      {Wrapper
        ? elements.map((element) => (
            <Wrapper key={shortid.generate()}>
              {element}
              &zwnj;
            </Wrapper>
          ))
        : elements}
    </span>
  );
};

Skeleton.defaultProps = {
  count: 1,
  duration: 1.2,
  width: null,
  wrapper: null,
  height: null,
  circle: false,
};

Skeleton.propTypes = {
  count: PropTypes.number,
  duration: PropTypes.number,
  width: PropTypes.number || PropTypes.string,
  wrapper: PropTypes.node,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  circle: PropTypes.bool,
};

export default Skeleton;
